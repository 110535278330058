import React from 'react';
import consultancy from "../assets/Logos/consultancy.png"
import logistics from "../assets/Logos/logistics.png"
import rentals from "../assets/Logos/rentals.png"
import trading from "../assets/Logos/trading.png"

function EmizoneSection() {
    return (
        <div className="bg-spGreen text-white">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center justify-between border-opacity-50 font-roboto">
                    <div className="w-full md:w-1/2 px-9 py-2 md:mt-[-100px]">
                        <h1 className="text-2xl md:text-3xl lg:text-5xl font-bold mb-6 md:mt-8 text-black">Why Emizone?</h1>
                        <p className="text-sm md:text-lg mb-4 text-black text-opacity-60 ">
                        Choose Emizone for your one-stop solution, where excellence meets efficiency. With our unparalleled expertise, extensive range of specialized equipment, and commitment to sustainability, we offer seamless trading, reliable rentals, expert consultancy, and integrated logistics services tailored to meet your business needs.
                        </p>
                        <button className="text-opacity-80 text-xs sm:text-lg md:text-md md:mt-10 hover:opacity-95 hover:text-spGreen text-white hover:border-green-300 py-1 px-2 sm:py-1 sm:px-4 md:py-2 md:px-8 "
                            style={{background:'#0F172A'}}
                            >
                            Get Started
                        </button>
                    </div>

                    <div className="w-full bg-white text-black md:w-1/2 grid grid-cols-1 sm:grid-cols-2 p-0 border ">
                        <div className="flex flex-col items-center justify-center p-4 border-b border-r border-slate-950 border-opacity-10">
                            <img src={trading} alt="Trading Icon" className="w-16 mb-4" />
                            <h2 className="text-xl font-bold mb-2">Trading</h2>
                            <p className="text-xs sm:text-sm text-center">
                            Emizone offers a wide array of specialized equipment for sale and rental, catering to various industries and sectors.
                            </p>
                        </div>
                        <div className="flex flex-col items-center justify-center p-4 border-b border-slate-950 border-opacity-10">
                            <img src={rentals} alt="Rentals Icon" className="w-16 mb-4" />
                            <h2 className="text-xl font-bold mb-2">Rentals</h2>
                            <p className="text-xs sm:text-sm text-center">
                            Emizone offers a wide array of specialized equipment for sale and rental, catering to various industries and sectors.
                            </p>
                        </div>
                        <div className="flex flex-col items-center justify-center p-4 border-r border-slate-950 border-opacity-10">
                            <img src={logistics} alt="Logistics Icon" className="w-16 mb-4" />
                            <h2 className="text-xl font-bold mb-2">Logistics</h2>
                            <p className="text-xs sm:text-sm text-center">
                            Comprehensive logistics services tailored to meet diverse business needs, including warehousing, distribution, and transportation solutions.
                            </p>
                        </div>
                        <div className="border border-spacing-x-60 flex flex-col items-center justify-center p-4 border-opacity-10">
                            <img src={consultancy} alt="Consultancy Icon" className="w-16 mb-4" />
                            <h2 className="text-xl font-bold mb-2">Consultancy</h2>
                            <p className="text-xs sm:text-sm text-center">
                            Emizone offers business management consultancy services, including inventory management, procurement, and logistics optimization.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmizoneSection;




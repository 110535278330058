import React from 'react'

const HomeSearchBar = () => {
    return (
      <>
        <form className="mt-2 flex items-center max-w-sm mx-auto">
          <label htmlFor="simple-search" className="sr-only">Search</label>
          <div className="relative w-full">
            <input
              type="text"
              id="simple-search"
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search..."
              required
            />
          </div>
          <button
            type="submit"
            className="p-1.5 ms-2 text-sm font-medium text-black bg-spGreen rounded-md border border-spGreen hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-opacity-90 dark:hover:bg-opacity-90 dark:focus:ring-green-800"
          >
            <svg
              className="w-3.5 h-3.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </form>
      </>
    );
  };
  

export default HomeSearchBar
import React, { useState } from 'react';
import logo from '../assets/Logos/Emizone Logo-01.png';
import HomeSearchBar from './HomeSearchBar';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
        <div className="bg-black opacity-85 flex flex-wrap items-center justify-between sm:justify-end z-[80] text-white absolute w-full px-4 gap-4">
            {/* Admin Info (email and phone) */}
            <div className="text-xs  md:text-sm flex items-center gap-2">
                <span className="text-gray-400 hover:text-spGreen cursor-pointer m-1">info@emizonegroup.com</span> | 
                <span className="text-gray-400 hover:text-spGreen cursor-pointer m-1">+971 508908937</span>
            </div>
        </div>

        {/* Divider Line */}
        <div style={{ backgroundColor: '#54B435', height: '1px', width: '100%' }}></div>

        {/* Main Navbar - Sticky only for larger screens */}
        <div className="bg-black opacity-85 flex items-center justify-between py-1 text-white z-[100] w-full absolute top-6 md:top-[29px] ">

            {/* Logo */}
            <img src={logo} alt='Logo' className="cursor-pointer h-10 sm:h-14 md:h-16 lg:h-18 max-h-40 object-contain"/>


            {/* Navbar menu for large screens */}
            <div className="hidden md:flex items-center space-x-6">
                <button className="hover:text-spGreen  md:text-md lg:text-lg ">Home</button>
                <button className="hover:text-spGreen  md:text-md lg:text-lg ">Services</button>
                <button className="hover:text-spGreen  md:text-md lg:text-lg ">Products</button>
                <button className="hover:text-spGreen  md:text-md lg:text-lg ">Company</button>
                <button className="hover:text-spGreen  md:text-md lg:text-lg ">Contact</button>
            </div>

            {/* Mobile Menu Button (Hamburger) for small screens */}
            <button
                onClick={toggleMobileMenu}
                className="md:hidden text-white p-2 rounded-md hover:bg-gray-600 absolute right-4"
            >
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </button>

            {/* Mobile Menu (Shows when button is clicked) */}
            <div
                className={`${
                    isMobileMenuOpen ? 'block' : 'hidden'
                } md:hidden absolute top-16 left-0 w-full bg-black opacity-99 space-y-4 py-4`}
            >
                <button className="hover:bg-black hover:text-spGreen block mx-10 text-center py-2">Home</button>
                <button className="hover:bg-black hover:text-spGreen block mx-10 text-center py-2">Services</button>
                <button className="hover:bg-black hover:text-spGreen block mx-10 text-center py-2">Products</button>
                <button className="hover:bg-black hover:text-spGreen block mx-10 text-center py-2">Company</button>
                <button className="hover:bg-black hover:text-spGreen block mx-10 text-center py-2">Contact</button>
                <button className="hover:bg-black hover:text-spGreen block mx-10 text-center py-2">Get Quote</button>
            </div>

            {/* Get Quote Button for larger screens - right side of the navbar */}
            <div className="hidden md:block mr-4">
                <button className="bg-spGreen mx-1 px-4 py-2 rounded-lg hover:opacity-90 hover:border-green-600 cursor-pointer md:text-sm lg:text-md">Request Quote</button>
            </div>
        </div>
        </>
    );
};

export default Navbar;

import React, { useState } from 'react';
import heroImage from '../assets/images/HeroImage.png';
import EmizoneSection from './EmizoneSection';

const Main = () => {
    const [banner, setBanner] = useState([]);
    return (
        <div className="w-full h-[500px] md:h-[850px] text-white relative">
            <div className="w-full h-full">
                <div className="absolute w-full h-[500px] md:h-[850px] bg-gradient-to-r from-black"></div>
                <img className="w-full h-full object-cover" src={heroImage} alt="Emizone banner" />
                <div className="absolute w-full top-[30%] p-4 md:p-8 ml-4">
                    <h1 className="text-xl sm:text-2xl md:text-4xl lg:6xl font-bold">
                        Emizone: Where Quality and <br /> Experience Create Perfection
                    </h1>
                    <p className="w-full text-xs sm:text-sm md:text-lg md:max-w-[70%] lg:max-w-[50%] text-gray-200 mb-2">
                        At Emizone Group, we empower businesses to embrace sustainability through innovative solutions and expert guidance.
                    </p>
                    <button className="border text-xs sm:text-sm md:text-md rounded hover:opacity-95 hover:text-black bg-spGreen text-white border-black py-1 px-2 sm:py-1 sm:px-4 md:py-2 md:px-8">
                        Get Started
                    </button>
                    <button className="mx-2 border text-xs sm:text-sm md:text-md rounded hover:opacity-95 hover:text-white bg-transparent text-spGreen border-green-500 py-1 px-2 sm:py-1 sm:px-3 md:py-2 md:px-5">
                        Request Quote
                    </button>

                </div>
            </div>

            {/* Emizone Section */}
            <div className="absolute top-[350px] sm:top-[400px] md:top-[800px] left-1/2 transform -translate-x-1/2 w-[80%] sm:w-[80%] md:w-[75%] lg:w-[80%] ">
                <EmizoneSection />
            </div>
        </div>
    );
};

export default Main;

import { Helmet } from 'react-helmet';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ title, content, children }) => (
    <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={content} />
        </Helmet>

        <Navbar />
            <div className="scrollbar-hide">{children}</div>
        {/* <Footer /> */}
    </>
);

export default Layout
import React from 'react'
import Main from '../components/Main'
import Card from '../components/Card'
import Layout from '../components/Layout'

const Home = () => {
  return (
    <Layout>
    {/* Main content area */}
    <div className="flex-grow h-screen">
        <Main/>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
            <div className='h-20 bg-red-900 w-[100%]'>helo</div>
            <div className='h-20 bg-green-900 w-[100%]'>helo</div>
            <div className='h-20 bg-blue-900 w-[100%]'>helo</div>
            <div className='h-20 bg-purple-700 w-[100%]'>helo</div>
            <div className='h-20 bg-yellow-600 w-[100%]'>helo</div>
           
    </div>
    </Layout>
  )
}

export default Home